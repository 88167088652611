<template>
  <div class="container">
    <b-form @submit.stop.prevent="form.id ? update() : save()">
      <h3>
        <b-icon icon="person-plus-fill" class="h4 m-1 align-bottom" />
      </h3>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- First name -->
          <b-form-group label-size="sm">
            <template slot="label">
              First name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="firstName-input"
              v-model.trim="$v.form.name.$model"
              size="sm"
              name="firstName-input"
              :value="form.name"
              :state="validateState('name')"
              aria-describedby="input-firstName-live-feedback"
              maxlength="100"
              @change="setValue('name', $event)"
            />
            <b-form-invalid-feedback id="input-firstName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <!-- Last name -->
          <b-form-group label-size="sm">
            <template slot="label">
              Last name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="lastName-input"
              v-model.trim="$v.form.lastName.$model"
              size="sm"
              name="lastName-input"
              :value="form.lastName"
              :state="validateState('lastName')"
              aria-describedby="input-lastName-live-feedback"
              maxlength="100"
              @change="setValue('lastName', $event)"
            />
            <b-form-invalid-feedback id="input-lastName-live-feedback">
              This is a required field and must at least 2 characters.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <!-- nickname -->
          <b-form-group label-size="sm">
            <template slot="label"> Nickname </template>
            <b-form-input
              id="nickname-input"
              size="sm"
              name="nickname-input"
              :value="form.nickname"
              aria-describedby="input-firstName-live-feedback"
              maxlength="100"
              @change="setValue('nickname', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Email -->
          <b-form-group label-size="sm">
            <template slot="label">
              Email <span class="text-danger"> *</span>
            </template>
            <b-form-input
              id="email-input"
              v-model.trim="$v.form.email.$model"
              size="sm"
              name="email-input"
              :formatter="lowerCaseFormatter"
              :value="form.email"
              :state="validateState('email')"
              aria-describedby="input-email-live-feedback"
              maxlength="255"
              @change="setValue('email', $event)"
            />
            <b-form-invalid-feedback id="input-email-live-feedback">
              This is a required field and must be a valid email.
            </b-form-invalid-feedback>
            <!--       <SendPasswordEmail
              v-if="
                !$v.form.email.$invalid &&
                this.userItem.id &&
                userHasPermission('agents', 'write')
              "
              :email="form.email"
            /> -->
          </b-form-group>
        </b-col>
        <!-- Stock code -->
        <b-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
          <b-form-group label-size="sm">
            <template slot="label">
              Stock code
              <span v-if="!isStockCodeOptional" class="text-danger"> *</span>
            </template>
            <b-form-input
              id="stockCode-input"
              size="sm"
              name="stockCode-input"
              :value="form.stockCode"
              aria-describedby="input-firstName-live-feedback"
              maxlength="5"
              @change="setValue('stockCode', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Office phone number -->
          <b-form-group label-size="sm">
            <template slot="label">
              Office phone number <span class="text-danger">*</span>
            </template>
            <b-input-group>
              <vue-tel-input
                v-model.trim="$v.form.officePhoneNumber.$model"
                size="sm"
                default-country="us"
                :input-options="options"
                :dropdown-options="telDdOptions"
                :auto-format="true"
                :valid-characters-only="true"
                mode="international"
                style-classes="z-index-2"
                :value="form.officePhoneNumber"
                @change="setValue('officePhoneNumber', $event)"
              />

              <b-form-input
                id="extension-input"
                v-model.trim="$v.form.extension.$model"
                size="md"
                type="number"
                maxlength="6"
                onkeydown="javascript: return event.keyCode == 69 ? false : true"
                class="append-input"
                placeholder="ext"
                name="extension-input"
                :value="form.extension"
                :state="validateState('extension')"
                @change="setValue('extension', $event)"
              />
              <b-form-invalid-feedback id="input-office-number-live-feedback">
                This field must at least contain 10 digits.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback id="input-extension-live-feedback">
                This is a numeric field
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <!-- Mobile phone -->
          <b-form-group label-size="sm" label="Mobile phone">
            <template slot="label"> Mobile phone </template>
            <vue-tel-input
              v-model.trim="form.mobilePhone"
              default-country="us"
              :input-options="options"
              :dropdown-options="telDdOptions"
              :auto-format="true"
              :valid-characters-only="true"
              :value="form.mobilePhone"
              mode="international"
              style-classes="z-index-2"
            />
            <b-form-invalid-feedback id="input-mobile-phone-live-feedback">
              This is a numeric field
            </b-form-invalid-feedback>
            <!--    <b-alert show variant="info" class="mt-2">
              Mobile Phone will be used for notifications
            </b-alert> -->
          </b-form-group>
        </b-col>
        <!-- Role -->
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm" label="Role">
            <template slot="label"> Role </template>
            <b-form-select
              v-model="form.rolesId"
              :options="roles"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col v-if="showRICompanyList" sm="12" md="6" lg="6" xl="6">
          <b-form-group label-size="sm" label="Role">
            <template slot="label"> Ri company list </template>
            <b-form-select
              v-model="form.companyId"
              :options="riCompanyList"
              text-field="companyName"
              value-field="id"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row />
      <b-row />

      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6" />
        <b-col sm="12" md="3" lg="3" xl="3" />
        <b-col sm="12" md="3" lg="3" xl="3">
          <b-btn
            v-if="userHasPermission('agents', 'write')"
            block
            size="lg"
            class="btn btn-success"
            :disabled="$v.form.$invalid || isLoading"
            type="submit"
          >
            Submit
            <b-spinner v-if="isLoading" small variant="white" />
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  email,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import {
  POST_EMAIL_VALIDATION,
  POST_USER,
} from "@/modules/buyer/registrationForm/actions";
import { mapState } from "vuex";
import { PUT_USER_BUYER } from "@/modules/buyer/users/actions";
import { generalMixin } from "@/modules/mixin.js";
/* import SendPasswordEmail from "@/modules/shared/SendPasswordEmail.vue";
 */ import { superAdminMixin } from "../../superAdmin/mixins";
import roles from "@/helpers/roles";
import { GET_RI_COMPANY_LIST } from "./actions";
export default {
  /*   components: { SendPasswordEmail },
   */ mixins: [generalMixin, superAdminMixin],
  props: ["userItem", "refreshData", "hideModal"],
  computed: {
    ...mapState({
      emailValidation: (state) => state.buyer.buyerStore.emailValidation.data,
      user: (state) => state.buyer.buyerStore.user,
      profile: (state) => state.auth.loginStore.profile.data.user,
    }),
    isLoading() {
      return this.user.status == "fetching" ? true : false;
    },
    isStockCodeOptional() {
      return this.form.rolesId != 7 && this.form.rolesId != 4;
    },
    showRICompanyList() {
      return this.form.rolesId === 9;
    },
  },

  data() {
    return {
      status: false,
      isAccepTerms: false,
      form: {
        id: null,
        name: null,
        lastName: null,
        email: null,
        officePhoneNumber: null,
        extension: null,
        mobilePhone: null,
        nickname: null,
        stockCode: null,
        rolesId: null,
        companyId: null,
      },
      options: {
        type: "tel",
        validCharactersOnly: true,
        maxlength: 14,
      },
      telDdOptions: {
        showDialCodeInList: true,
        showFlags: true,
        showDialCodeInSelection: false,
      },
      roles: roles,
      riCompanyList: [],
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      extension: {
        numeric,
      },
      officePhoneNumber: {
        required,
        minLength: minLength(10),
      },
      rolesId: {
        required,
      },

      stockCode: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.rolesId == 7 || nestedModel.rolesId == 4;
        }),
      },
    },
  },
  mounted() {
    if (this.userItem.id) {
      this.formatForm();
    }
    this.getRiCompanyList();
  },
  methods: {
    closeModal(callback) {
      callback();
    },
    setValue(key, value) {
      this.form[key] = value;
      this.$v.form[key].$touch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async save() {
      await this.verifyEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(POST_USER, payload)
        .then(() => {
          this.makeToast("Success", "User created successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async update() {
      await this.verifyEmail();
      if (this.emailValidation.message === "invalid email") {
        return;
      }
      const payload = this.formatPayload();
      this.$store
        .dispatch(PUT_USER_BUYER, payload)
        .then(() => {
          this.makeToast("Success", "User updated successfully", "success");
          this.refreshData();
          this.hideModal();
        })
        .catch(() => {
          this.$root.$bvToast.toast(this.user.error.response.data.message, {
            title: "Warning",
            variant: "warning",
          });
        });
    },
    async verifyEmail() {
      const payload = {
        userId: this.form.id,
        email: this.form.email,
      };
      await this.$store
        .dispatch(POST_EMAIL_VALIDATION, payload)
        .then(() => {
          if (this.emailValidation.message === "invalid email") {
            this.$root.$bvToast.toast("This email has already been taken", {
              title: "Warning",
              variant: "warning",
            });
          }
        })
        .catch(() => {
          this.$root.$bvToast.toast("error", {
            title: "Error",
            variant: "warning",
          });
        });
    },
    goTo() {
      if (this.$route.path !== "/") this.$router.push({ path: "/" });
    },
    formatPayload() {
      const payload = {
        id: this.userItem.id ? this.userItem.id : null,
        transCanada: true,
        firstName: this.form.name,
        lastName: this.form.lastName,
        email: this.form.email,
        nickname: this.form.nickname,
        rolesId: [this.form.rolesId],
        stockCode: this.form.stockCode ? this.form.stockCode : null,
        userDetails: {
          extension: this.form.extension,
          mobileNumber: this.form.mobilePhone,
          officePhoneNumber: this.form.officePhoneNumber,
          companyId: this.form.companyId,
        },
        corporation: 1,
      };

      return payload;
    },

    formatForm() {
      console.log(this.userItem);
      this.form.id = this.userItem.id;
      this.form.name = this.userItem.firstName;
      this.form.lastName = this.userItem.lastName;
      this.form.email = this.userItem.email;
      this.form.nickname = this.userItem.nickname;
      this.form.extension = this.userItem.userDetails.extension;
      this.form.mobilePhone = this.userItem.userDetails.mobileNumber;
      this.form.officePhoneNumber = this.userItem.userDetails.officePhoneNumber;
      this.form.stockCode = this.userItem?.corporation.stockCode
        ? this.userItem?.corporation.stockCode
        : null;
      this.form.rolesId = this.userItem.roles[0].id;
      this.form.companyId = this.userItem.userDetails.companiesId;
    },

    getRiCompanyList() {
      this.$store.dispatch(GET_RI_COMPANY_LIST).then((response) => {
        this.riCompanyList = response;
      });
    },
  },
};
</script>

<style scoped>
.append-input {
  max-width: 40%;
}
</style>
