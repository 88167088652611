<template>
  <div class="mt-4 mb-1">
    <b-row class="mr-1 ml-1">
      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="d-flex justify-content-center"
      >
        <slot name="leftSide" />
      </b-col>

      <b-col xs="12" sm="12" md="12" lg="4" xl="4" class="">
        <!--         <b-input-group
          size="sm"
          class="
            shadows
            rounded-right rounded-left
            align-middle
            bg-dark-green
            mb-2
          "
        >
          <b-form-input
            id="filterInput"
            class="filter-input rounded-right rounded-left"
            type="search"
            v-model="searchText"
            @change="search($event)"
          />
          <b-input-group-append class="rounded-right rounded-left">
            <b-icon
              icon="search"
              variant="yellow"
              class="h5 m-2 align-middle"
            />
          </b-input-group-append>

          <font-awesome-icon
            class="icon-color cursor-pointer h5 m-2 color-yellow"
            :icon="['far', 'magnifying-glass-plus']"
            v-b-toggle.advance-search-sidebar
          />
        </b-input-group> -->
      </b-col>
      <b-col
        v-if="isMobile"
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="text-center"
      >
        <b-button
          variant="dark-green"
          size="lg"
          @click="toggleBarCodeReader(true)"
        >
          <b-icon icon="camera" variant="" />
        </b-button>
      </b-col>

      <b-col
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        class="text-center"
        :class="{ 'mt-2': isMobile }"
      >
        <!--     <b-button
          v-if="userHasPermission('stock', 'write')"
          variant="success"
          @click="addVehicle()"
        >
          <b-icon icon="plus" class="mt-1" />
        </b-button> -->
      </b-col>
    </b-row>

    <b-modal
      id="uploadVehicleModal"
      title="Upload vehicle"
      size="lg"
      hide-footer
    >
      <div class="upload-vehicle">
        <SearchVehicleByVin
          :show-progress-bar="false"
          :show-upload-vehicles-file="false"
          :redirect-to-table="false"
          :close-upload-vehicle-modal="true"
        />
      </div>
    </b-modal>
    <b-modal
      id="searchVehicleModal"
      v-model="showBarCodeReader"
      modal-class="modal-fullscreen"
      title="search vehicle"
      size="lg"
      hide-footer
      @hide="showBarCodeReader = false"
    >
      <BarCodeReader
        v-if="showBarcode"
        @decode="onDecode($event)"
        @onCancel="toggleBarCodeReader($event)"
      />
    </b-modal>

    <div>
      <b-sidebar
        id="advance-search-sidebar"
        title="Advance search"
        shadow
        width="30rem"
      >
        <AdvanceSearch class="mx-4" />
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { superAdminMixin } from "../mixins";
import SearchVehicleByVin from "@/modules/admin/vehicles/registry/SearchVehicleByVin";
import BarCodeReader from "@/modules/shared/BarCodeReader";
import AdvanceSearch from "@/modules/admin/vehicles/list/Search";
export default {
  components: {
    SearchVehicleByVin,
    BarCodeReader,
    AdvanceSearch,
  },
  mixins: [superAdminMixin],
  props: ["isMobile"],
  data() {
    return {
      searchText: "",
      showBarCodeReader: false,
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.superAdminStore.vehiclesInfo.filters,
    }),
    showBarcode() {
      return this.showBarCodeReader;
    },
  },
  mounted() {
    this.searchText = this.filters.search ? this.filters.search : "";
    this.$store.commit(
      "setAddFunction",
      this.userHasPermission("stock", "write") && !this.isUserRI()
        ? this.addVehicle
        : null
    );
    this.$store.commit("setSearchFunction", this.search);
  },
  methods: {
    addVehicle() {
      this.$router.push({
        name: "vehicle.search",
      });
    },
    onDecode(code) {
      this.search(code);

      this.toggleBarCodeReader(false);
    },
    toggleBarCodeReader(show) {
      this.showBarCodeReader = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  background-color: #1d3030;
  opacity: 1;
  color: white;
  border: none;
}
.form-control:focus {
  background-color: #1d3030;
  color: white;
  border-color: #1d3030;
}
.shadows {
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.5);
}
.upload-vehicle {
  margin-top: -4rem;
}
@media (min-width: 600px) {
  .upload-vehicle {
    margin-left: 32%;
  }
}
.color-yellow {
  color: #f39220;
}
</style>
