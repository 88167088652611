<template>
  <side-bar v-if="profile">
    <template slot="logo">
      <!--       <img :src="'img/QAS.png'" class="navbar-brand-img" alt="Sidebar logo" />
 -->
      <CorporationSelect />
    </template>
    <template slot="links">
      <sidebar-item
        v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
        :disabled="companyIsPending"
        :link="{
          name: 'Marketplace',
          path: {
            name: 'master.marketplace',
          },
          icon: ['far', 'cart-shopping-fast'],
          active: true,
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
        :disabled="companyIsPending"
        :active="$route.name && $route.name.includes('master.corporation')"
        :link="{
          name: 'Corporations',
          path: {
            name: 'master.corporation',
          },
          icon: ['far', 'building'],
        }"
      >
      </sidebar-item>

      <sidebar-item
        v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
        :disabled="companyIsPending"
        :active="$route.name && $route.name.includes('master.super-admin')"
        :link="{
          name: 'Super admins',
          path: {
            name: 'master.super-admin',
          },
          icon: ['far', 'user-group'],
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="userHasMenuPermission('Logistics')"
        :link="{
          name: 'Logistics',
          icon: ['far', 'truck-fast'],
        }"
      >
        <sidebar-item
          :link="{
            name: 'Vehicles',
            path: {
              name: 'superadmin.vehicles.table.logistic',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">Vehicles </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          :disabled="companyIsPending"
          :link="{
            name: 'Release date',
            path: {},
            icon: ['far', 'calendar-day'],
          }"
        >
          <template slot="title">
            <div class="nav-link" @click="showModal('bulk-release-date-modal')">
              <i>
                <font-awesome-icon :icon="['far', 'calendar-day']" />
              </i>
              <span class="nav-link-text">Bulk release date</span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="profile && userHasPermission('buyChecks', 'read')"
          :disabled="companyIsPending"
          :link="{
            name: 'Buy checks',
            path: {
              name: 'superadmin.checks.actives',
            },
            icon: ['far', 'money-check'],
            active:
              ($route.name &&
                $route.name.includes('superadmin.checks.actives')) ||
              ($route.name &&
                $route.name.includes('superadmin.checks.delivered')),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'money-check']" />
              </i>
              <span class="nav-link-text">Buy checks </span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>

      <sidebar-item
        v-if="userHasMenuPermission('Business')"
        :link="{
          name: 'Business',
          path: {
            name: 'superadmin.vehicles.table.inventory',
          },
          icon: ['far', 'cars'],
          active: $route.name.includes('superadmin.vehicles.table.inventory'),
        }"
      >
        <sidebar-item
          v-if="userHasPermission('addons', 'read')"
          :link="{
            name: 'Business',
            path: {
              name: 'superadmin.vehicles.table.inventory',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">Business </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('addons', 'read')"
          :link="{
            name: 'Addons type',
            path: {
              name: 'superadmin.addons-type',
            },
            icon: ['far', 'puzzle-piece'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'puzzle-piece']" />
              </i>
              <span class="nav-link-text"> Suppliers category </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('addons', 'read')"
          :link="{
            name: 'Upload txt file',
            path: {
              name: 'superadmin.addons-file',
            },
            icon: ['far', 'file-csv'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-csv']" />
              </i>
              <span class="nav-link-text">Upload txt files </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('fees', 'read')"
          :link="{
            name: 'Fees',
            path: {
              name: 'superadmin.fees',
            },
            icon: ['far', 'hand-holding-dollar'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'hand-holding-dollar']" />
              </i>
              <span class="nav-link-text">Fees </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Reports',
            path: {
              name: 'superadmin.custom-reports',
            },
            icon: ['far', 'file-chart-column'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-chart-column']" />
              </i>
              <span class="nav-link-text">Reports </span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>
      <sidebar-item
        v-if="userHasMenuPermission('Accounting')"
        :link="{
          name: 'Accounting',
          path: {
            name: 'superadmin.vehicles.table.inventory',
          },
          icon: ['far', 'calculator'],
          active: $route.name.includes('superadmin.vehicles.table.inventory'),
        }"
      >
        <sidebar-item
          :link="{
            name: 'Vehicles',
            path: {
              name: 'superadmin.vehicles.table.accounting',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">Vehicles </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Run list',
            path: {
              name: 'superadmin.run-list',
            },
            icon: ['far', 'calculator'],
            active: $route.name && $route.name.includes('superadmin.run-list'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'calculator']" />
              </i>
              <span class="nav-link-text">Run list </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Transfer between agents',
            path: {
              name: 'superadmin.transfer-between-agent',
            },
            icon: ['far', 'truck-container'],
            active:
              $route.name &&
              $route.name.includes('superadmin.transfer-between-agent'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'truck-container']" />
              </i>
              <span class="nav-link-text">Transfer between agents </span>
            </div>
          </template>
        </sidebar-item>
        <!--   <sidebar-item
          :link="{
            name: 'Account statement',
            path: {
              name: 'superadmin.account-statement',
            },
            icon: ['far', 'file-invoice-dollar'],
            active:
              $route.name &&
              $route.name.includes('superadmin.account-statement'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-invoice-dollar']" />
              </i>
              <span class="nav-link-text">Account statement </span>
            </div>
          </template>
        </sidebar-item> -->
        <sidebar-item
          v-if="userHasPermission('addons', 'read')"
          :link="{
            name: 'Upload text file',
            path: {
              name: 'superadmin.addons-file',
            },
            icon: ['far', 'file-csv'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-csv']" />
              </i>
              <span class="nav-link-text">Upload text file </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="true"
          :link="{
            name: 'Commissions',
            path: {
              name: 'superadmin.interest',
            },
            icon: ['far', 'money-bill-transfer'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'money-bill-transfer']" />
              </i>
              <span class="nav-link-text">Commissions </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('taxes', 'read')"
          :link="{
            name: 'Taxes',
            path: {
              name: 'superadmin.taxes',
            },
            icon: ['far', 'sack-dollar'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'sack-dollar']" />
              </i>
              <span class="nav-link-text">Taxes </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="userHasPermission('fx', 'read')"
          :link="{
            name: 'Fx',
            path: {
              name: 'superadmin.fx',
            },
            icon: ['far', 'dollar-sign'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'dollar-sign']" />
              </i>
              <span class="nav-link-text">Fx </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Exchange rate contract',
            path: {
              name: 'superadmin.exchange-contract',
            },
            icon: ['far', 'sack-dollar'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'sack-dollar']" />
              </i>
              <span class="nav-link-text">Exchange rate contract </span>
            </div>
          </template>
        </sidebar-item>
        <!--         <sidebar-item
          :link="{
            name: 'Agent commissions',
            path: {
              name: 'superadmin.agent-commission',
            },
            icon: ['far', 'money-bill-transfer'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'money-bill-transfer']" />
              </i>
              <span class="nav-link-text">Agent commissions </span>
            </div>
          </template>
        </sidebar-item> -->
        <sidebar-item
          :link="{
            name: 'Commissions summary',
            path: {
              name: 'superadmin.commission-summary',
            },
            icon: ['far', 'money-bill-transfer'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'money-bill-transfer']" />
              </i>
              <span class="nav-link-text">Commissions summary </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Driver',
            path: {
              name: 'superadmin.transportation',
            },
            icon: ['far', 'id-card'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'id-card']" />
              </i>
              <span class="nav-link-text">Drivers </span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>

      <sidebar-item
        v-if="userHasMenuPermission('InFleet')"
        :link="{
          name: 'In fleet',
          path: {
            name: 'superadmin.vehicles.table.admin',
          },
          icon: ['far', 'files'],
          active: $route.name.includes('superadmin.vehicles.table.admin'),
        }"
      >
        <sidebar-item
          :link="{
            name: 'Vehicles',
            path: {
              name: 'superadmin.vehicles.table.admin',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">In fleet</span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="profile && userHasPermission('registration', 'read')"
          :disabled="companyIsPending"
          :active="
            $route.name && $route.name.includes('superadmin.registration')
          "
          :link="{
            name: 'Registration',
            path: {
              name: 'superadmin.registration',
            },
            icon: ['far', 'file-invoice-dollar'],
            active:
              $route.name && $route.name.includes('superadmin.registration'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-invoice-dollar']" />
              </i>
              <span class="nav-link-text">Registration </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="profile && userHasPermission('creditRequest', 'read')"
          :disabled="companyIsPending"
          :link="{
            name: 'Credit request',
            path: {
              name: 'superadmin.credit-request',
            },
            icon: ['far', 'file-invoice-dollar'],
            active:
              $route.name && $route.name.includes('superadmin.credit-request'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-invoice-dollar']" />
              </i>
              <span class="nav-link-text">Credit request </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          :disabled="companyIsPending"
          :link="{
            name: 'Reserve',
            path: {
              name: 'superadmin.reserve',
            },
            icon: ['far', 'message-dollar'],
            active: $route.name && $route.name.includes('superadmin.reserve'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'message-dollar']" />
              </i>
              <span class="nav-link-text">Reserve</span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>
      <sidebar-item
        v-if="userHasMenuPermission('Export')"
        :link="{
          name: 'Export',
          path: {
            name: 'superadmin.vehicles.table.export',
          },
          icon: ['far', 'globe'],
          active: $route.name.includes('superadmin.vehicles.table.export'),
        }"
      >
        <sidebar-item
          :link="{
            name: 'Vehicles',
            path: {
              name: 'superadmin.vehicles.table.export',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">Export</span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="userHasPermission('exportLocations', 'read')"
          :link="{
            name: 'Export locations',
            path: {
              name: 'superadmin.export-location',
            },
            icon: ['far', 'location-dot'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'location-dot']" />
              </i>
              <span class="nav-link-text">Export locations </span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          v-if="profile && userHasPermission('transfer', 'read')"
          :disabled="companyIsPending"
          :link="{
            name: 'Transfer',
            path: {},
            icon: ['far', 'truck-container'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'truck-container']" />
              </i>
              <span
                class="nav-link-text"
                @click="showModal('bulk-transfer-modal')"
                >Bulk transfers</span
              >
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          v-if="
            profile &&
            userHasPermission('exportLoads', 'read') &&
            userHasMenuPermission('ExportLoads')
          "
          :disabled="companyIsPending"
          :link="{
            name: 'Export loads',
            path: {
              name: 'superadmin.export-load',
            },
            icon: ['far', 'truck-container'],
            active:
              $route.name && $route.name.includes('superadmin.export-load'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'truck-container']" />
              </i>
              <span class="nav-link-text">Export loads</span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Title manager',
            path: {
              name: 'superadmin.title-manager',
            },
            icon: ['far', 'files'],
            active:
              $route.name && $route.name.includes('superadmin.title-manager'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'files']" />
              </i>
              <span class="nav-link-text">Title manager</span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'R.I,',
            path: {
              name: 'superadmin.ri-companies',
            },
            icon: ['far', 'users'],
            active:
              $route.name && $route.name.includes('superadmin.ri-companies'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'users']" />
              </i>
              <span class="nav-link-text">R.I.</span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>

      <sidebar-item
        v-if="userHasMenuPermission('Yard')"
        :link="{
          name: 'Yard',
          path: {
            name: 'superadmin.vehicles.table.yard',
          },
          icon: ['far', 'cars'],
          active: $route.name.includes('superadmin.vehicles.table.yard'),
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="userHasMenuPermission('Sales')"
        :link="{
          name: 'Sales',
          path: {
            name: 'superadmin.vehicles.table.sales',
          },
          icon: ['far', 'cars'],
          active: $route.name.includes('superadmin.vehicles.table.sales'),
        }"
      >
        <sidebar-item
          :link="{
            name: 'Vehicles',
            path: {
              name: 'superadmin.vehicles.table.sales',
            },
            icon: ['far', 'cars'],
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'cars']" />
              </i>
              <span class="nav-link-text">Sales</span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :disabled="companyIsPending"
          :link="{
            name: 'Invoice request',
            path: {
              name: 'superadmin.invoice',
            },
            icon: ['far', 'file-invoice-dollar'],
            active: $route.name && $route.name.includes('superadmin.invoice'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'file-invoice-dollar']" />
              </i>
              <span class="nav-link-text">Invoice request</span>
            </div>
          </template>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Transfer between agents',
            path: {
              name: 'superadmin.transfer-between-agent',
            },
            icon: ['far', 'truck-container'],
            active:
              $route.name &&
              $route.name.includes('superadmin.transfer-between-agent'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'truck-container']" />
              </i>
              <span class="nav-link-text">Transfer between agents </span>
            </div>
          </template>
        </sidebar-item>
        <sidebar-item
          :disabled="companyIsPending"
          :link="{
            name: 'Reserve',
            path: {
              name: 'superadmin.reserve',
            },
            icon: ['far', 'message-dollar'],
            active: $route.name && $route.name.includes('superadmin.reserve'),
          }"
        >
          <template slot="title">
            <div class="nav-link">
              <i>
                <font-awesome-icon :icon="['far', 'message-dollar']" />
              </i>
              <span class="nav-link-text">Reserve</span>
            </div>
          </template>
        </sidebar-item>
      </sidebar-item>

      <sidebar-item
        v-if="
          profile &&
          userHasPermission('cluster', 'read') &&
          userHasMenuPermission('Cluster')
        "
        :disabled="companyIsPending"
        :link="{
          name: 'Cluster',
          path: {},
          icon: ['far', 'gauge-simple'],
        }"
      >
        <template slot="title">
          <div class="nav-link" @click="showModal('bulk-cluster-modal')">
            <i>
              <font-awesome-icon :icon="['far', 'gauge-simple']" />
            </i>
            <span class="nav-link-text">Cluster</span>
          </div>
        </template>
      </sidebar-item>

      <sidebar-item
        v-if="
          (profile && userHasPermission('companies', 'read'),
          userHasMenuPermission('Companies'))
        "
        :disabled="companyIsPending"
        :link="{
          name: 'Companies',
          path: {
            name: 'admin.users.validate',
          },
          icon: ['far', 'user-group'],
          active: $route.name && $route.name.includes('admin.users.validate'),
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="userHasMenuPermission('Task')"
        :link="{
          name: 'Tasks',
          path: {
            name: 'superadmin.tasks',
          },
          icon: ['far', 'list-check'],
          active: $route.name && $route.name.includes('superadmin.tasks'),
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="profile && profile.user.roles[0].name == 'ROLE_RI'"
        :link="{
          name: 'Vehicles',
          path: {
            name: 'ri.vehicles',
          },
          icon: ['far', 'cars'],
          active: $route.name && $route.name.includes('ri.vehicles'),
        }"
      >
      </sidebar-item>
      <sidebar-item
        v-if="profile && (userIsRI || userIsSuperAdmin)"
        :link="{
          name: 'Title manager',
          path: {
            name: 'superadmin.title-manager',
          },
          icon: ['far', 'files'],
          active:
            $route.name && $route.name.includes('superadmin.title-manager'),
        }"
      >
      </sidebar-item>

      <sidebar-item
        v-if="profile && !isUserRI"
        :link="{
          name: 'Release date',
          path: {},
          icon: ['far', 'calendar-day'],
        }"
      >
        <template slot="title">
          <div class="nav-link" @click="showModal('bulk-release-date-modal')">
            <i>
              <font-awesome-icon :icon="['far', 'calendar-day']" />
            </i>
            <span class="nav-link-text">Bulk release date</span>
          </div>
        </template>
      </sidebar-item>

      <sidebar-item
        v-if="
          userHasMenuPermission('Configuration') ||
          profile.user.roles[0].name == 'ROLE_MASTER'
        "
        :link="{
          name: 'Configuration ',
          icon: ['far', 'wrench'],
        }"
      >
        <template slot="title">
          <div class="nav-link">
            <i>
              <font-awesome-icon :icon="['far', 'wrench']" />
            </i>
            <span class="nav-link-text">Configuration </span>
          </div>
        </template>
        <template slot="default">
          <sidebar-item
            v-if="profile && profile.user.roles[0].name == 'ROLE_MASTER'"
            :link="{
              name: 'Corporation permissions',
              path: {
                name: 'master.assign.corporation',
              },
              icon: ['far', 'lock-keyhole'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'lock-keyhole']" />
                </i>
                <span class="nav-link-text">Corporation permissions </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="
              (profile && profile.user.roles[0].name == 'ROLE_MASTER') ||
              profile.user.roles[0].name == 'ROLE_SUPER_ADMIN'
            "
            :link="{
              name: 'Marketplace permissions',
              path: {
                name: 'master.assign.marketplace',
              },
              icon: ['far', 'lock-keyhole'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'lock-keyhole']" />
                </i>
                <span class="nav-link-text">Marketplace permissions </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('permissions', 'read')"
            :link="{
              name: 'Permissions',
              path: {
                name: 'superadmin.permissions',
              },
              icon: ['far', 'lock-keyhole'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'lock-keyhole']" />
                </i>
                <span class="nav-link-text">Permissions </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('permissions', 'read')"
            :link="{
              name: 'Menu permissions',
              path: {
                name: 'superadmin.menu-permissions',
              },
              icon: ['far', 'lock-keyhole'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'lock-keyhole']" />
                </i>
                <span class="nav-link-text">Menu permissions </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('permissions', 'read')"
            :link="{
              name: 'Task types by user',
              path: {
                name: 'superadmin.task-types-by-user',
              },
              icon: ['far', 'lock-keyhole'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'lock-keyhole']" />
                </i>
                <span class="nav-link-text">Task types by user </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('drivers', 'read')"
            :link="{
              name: 'Drivers',
              path: {
                name: 'superadmin.drivers',
              },
              icon: ['far', 'id-card'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'id-card']" />
                </i>
                <span class="nav-link-text">Drivers </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('providers', 'read')"
            :link="{
              name: 'Suppliers',
              path: {
                name: 'work-order.providers',
              },
              icon: ['far', 'building'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'building']" />
                </i>
                <span class="nav-link-text">Suppliers </span>
              </div>
            </template>
          </sidebar-item>

          <sidebar-item
            v-if="userHasPermission('agents', 'read')"
            :link="{
              name: 'Users',
              path: {
                name: 'admin.agent',
              },
              icon: ['far', 'user-group'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'user-group']" />
                </i>
                <span class="nav-link-text">Users </span>
              </div>
            </template>
          </sidebar-item>

          <sidebar-item
            v-if="userHasPermission('companies', 'read')"
            :link="{
              name: 'External users',
              path: {
                name: 'admin.assign-company',
              },
              icon: ['far', 'user-group'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'user-group']" />
                </i>
                <span class="nav-link-text">External users </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('addons', 'read')"
            :link="{
              name: 'Addons type',
              path: {
                name: 'superadmin.addons-type',
              },
              icon: ['far', 'puzzle-piece'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'puzzle-piece']" />
                </i>
                <span class="nav-link-text"> Suppliers category </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            v-if="userHasPermission('addons', 'read')"
            :link="{
              name: 'Addons file',
              path: {
                name: 'superadmin.addons.assign',
              },
              icon: ['far', 'file-csv'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon :icon="['far', 'file-csv']" />
                </i>
                <span class="nav-link-text">Assign suppliers category </span>
              </div>
            </template>
          </sidebar-item>
          <sidebar-item
            :link="{
              name: 'Drivers price',
              path: {
                name: 'superadmin.driver-price',
              },
              icon: ['far', 'money-check-dollar-pen'],
            }"
          >
            <template slot="title">
              <div class="nav-link">
                <i>
                  <font-awesome-icon
                    :icon="['far', 'money-check-dollar-pen']"
                  />
                </i>
                <span class="nav-link-text">Drivers price </span>
              </div>
            </template>
          </sidebar-item>
        </template>
      </sidebar-item>
    </template>
    <template slot="links-after">
      <hr class="my-2" />

      <b-nav class="navbar-nav mb-md-3">
        <sidebar-item
          :link="{
            name: 'Logout',
            path: {},
            icon: ['far', 'right-from-bracket'],
          }"
        >
          <template slot="title">
            <div class="nav-link" @click="logout()">
              <i>
                <font-awesome-icon :icon="['far', 'right-from-bracket']" />
              </i>
              <span class="nav-link-text">Logout </span>
            </div>
          </template>
        </sidebar-item>
      </b-nav></template
    >
    <BulkClusterModal />
    <BulkTransferModal />
    <BulkReleaseDateModal />
    <b-modal
      id="pricing-modal"
      ref="pricing-modal"
      size="md"
      hide-footer
      body-class="pl-0 pr-0"
      scrollable
      ok-disabled
    >
      <Pricing />
    </b-modal>
  </side-bar>
</template>

<script>
import { mapState } from "vuex";
import { generalMixin } from "../modules/mixin";
import { superAdminMixin } from "../modules/superAdmin/mixins";
import Pricing from "@/modules/pricing/calculator/Calculator.vue";
import BulkClusterModal from "@/modules/superAdmin/bulk-actions/cluster/BulkClusterModal.vue";
import BulkTransferModal from "@/modules/superAdmin/bulk-actions/transfers/BulkTransferModal.vue";
import CorporationSelect from "./CorporationSelect";
import BulkReleaseDateModal from "../modules/superAdmin/bulk-actions/release-date/ReleaseDateModal.vue";
export default {
  name: "SideBarMenu",
  mixins: [generalMixin, superAdminMixin],
  components: {
    Pricing,
    BulkClusterModal,
    BulkTransferModal,
    CorporationSelect,
    BulkReleaseDateModal,
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.loginStore.profile.data,
      hasCorporation: (state) =>
        state.auth.loginStore.corporation ? true : false,
    }),
  },
  methods: {
    openPricingModal() {
      this.$bvModal.show("pricing-modal");
    },
    logout() {
      this.$store
        .dispatch("AUTH_LOGOUT")
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$root.$bvToast.toast("Error to logout", {
            title: "Warning",
            variant: "danger",
          });
        });
    },
  },
};
</script>

<style></style>
