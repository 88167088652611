import {
  SET_VEHICLE_INFO,
  GET_VEHICLES_INFO,
  SET_VEHICLES_INFO_FILTERS,
  GET_VEHICLES_INFO_FOR_RI_ROLE,
} from "./actions";
import {
  SET_VEHICLES_CHECKS_FILTERS,
  SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS,
  GET_VEHICLE_CONDITIONAL_REPORTS,
  SET_VEHICLE_NOTES_FILTERS,
  GET_VEHICLE_NOTES,
} from "./components/actions";
import { GET_PAYMENTS } from "@/modules/vehicle/components/payments/actions.js";
import { POST_VEHICLE_BY_STOCK_SUCCESS } from "@/modules/vehicle/actions.js";
import { mapState } from "vuex";
const colors = {
  GREEN: "#54b962",
  YELLOW: "#f8c12c",
  ORANGE: "#fd7e14",
  GREY: "#aeaeaf",
  BLUE: "#02afaa",
}
const superAdminMixin = {
  computed: {
    ...mapState({
      userPermissions: (state) =>
        state.auth.loginStore.profile.data.user.listPermissions,
      userMenuPermissions: (state) =>
        state.auth.loginStore.profile.data.user.menuPermissions,
      vehicleAnchor: (state) => state.superAdminStore.vehicleAnchor,
      vehicles: (state) => state.superAdminStore.vehiclesInfo,
      profileStore: (state) => state.auth.loginStore.profile.data,
    }),
    corporationSelected() {
      const corporation = localStorage.getItem("corporation");
      let corporationSelect = this.profileStore.user.corporationPermissions.find(
        (c) => c.id == corporation
      );
      return corporationSelect
        ? corporationSelect
        : this.profileStore.user.corporationPermissions[0]
          ? this.profileStore.user.corporationPermissions[0]
          : { name: "" };
    }
  },
  data() {
    return {};
  },

  methods: {
    getVehiclesInfo() {
      const vehiclesInfoType = this.isUserRI() ? GET_VEHICLES_INFO_FOR_RI_ROLE : GET_VEHICLES_INFO;
      this.$store
        .dispatch(vehiclesInfoType)
        .then(() => {
          this.getBoardInformationByRouter()
          this.scrollToAnchor(this.vehicleAnchor);
        })
        .catch();
    },
    isUserRI() {
      return this.profileStore.user.roles.map((role) => role.name).includes("ROLE_RI") ? true : false;
    },

    getBoardInformationByRouter() {
      switch (this.$route.name) {
        case "superadmin.vehicles.table.logistic":
          this.getLogisticBoardInformation()
          break;
        case "superadmin.vehicles.table.admin":
          this.getAdminBoardInformation()
          break;
        case "superadmin.vehicles.table.inventory":
          this.getBusinessBoardInformation()
          break;
        case "superadmin.vehicles.table.export":
          this.getExportBoardInformation()
          break;
        case "superadmin.vehicles.table.yard":
          this.getYardBoardInformation()
          break;

        default:
          break;
      }
    },
    getLogisticBoardInformation() {
      this.$store.dispatch("GET_BOARD_LOGISTIC").then(() => { });
    },
    getAdminBoardInformation() {
      this.$store.dispatch("GET_BOARD_ADMIN").then(() => { });
    },
    getBusinessBoardInformation() {
      this.$store.dispatch("GET_BOARD_BUSINESS").then(() => { });
    },
    getExportBoardInformation() {
      this.$store.dispatch("GET_BOARD_EXPORT").then(() => { });
    },
    getYardBoardInformation() {
      this.$store.dispatch("GET_BOARD_YARD").then(() => { });
    },

    goToDashBoard(vehicle) {
      this.$router.push({
        name: "superadmin.vehicles.dashboard",
      });
      this.$store.commit(SET_VEHICLE_INFO, vehicle);
    },
    goToVehicleUnitPage(id) {

      const userHasPermission = this.userHasPermission(
        'vin', "read"
      );
      if (userHasPermission) {
        /* encrypt id */
        const encryptedId = this.$CryptoJS.AES.encrypt(
          JSON.stringify(id),
          "Secret Passphrase"
        ).toString();

        this.$router.push({
          name: "superadmin.vehicles.unit",
          params: {

            id: encryptedId,
          },
        });

      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }

    },
    goToInitialInformation(vehicle, permission) {
      const userHasPermission = this.userHasPermission(
        permission.module,
        permission.action
      );
      if (userHasPermission) {
        this.$router.push({
          name: "vehicle.search.stock",
          params: { stock: vehicle.stockNumber },
        });
      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },
    changePage(page) {
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { page },
      });
      this.getVehiclesInfo();
    },
    resetFilters() {
      /* clear query in url */
      if (this.$route.query && Object.keys(this.$route.query).length > 0) {
        this.$router.push({
          name: this.$route.name,
          query: {},
        });
      }


      this.$store.commit(SET_VEHICLES_INFO_FILTERS, { reset: true });

    },
    search(search) {
      this.resetFilters();
      this.$store.commit(SET_VEHICLES_INFO_FILTERS, {
        fields: { search, page: 1 },
      });
      this.getVehiclesInfo();
    },

    openModal(vehicle, modal, permission) {
      if (modal === 'sold-modal' && (!this.validateOpenModal(vehicle))) {
        if (!this.validateOpenModal(vehicle)) {
          return this.toastMessage(
            "This vehicle has an external consignment active",
            "Warning",
            "warning"
          );
        }
      }
      const userHasPermission = this.userHasPermission(
        permission.module,
        permission.action
      );
      if (userHasPermission) {
        if (modal === "checks-modal") {
          this.$store.commit(POST_VEHICLE_BY_STOCK_SUCCESS, vehicle);
          this.$store.commit(SET_VEHICLE_INFO, vehicle);
        }

        const userHasPermission = this.userHasPermission(
          permission.module,
          permission.action
        );
        if (userHasPermission) {
          if (modal === "checks-modal") {
            this.$store.commit(POST_VEHICLE_BY_STOCK_SUCCESS, vehicle);
            this.$store.commit(SET_VEHICLE_INFO, vehicle);
          }
          this.$store.commit(SET_VEHICLE_INFO, vehicle);
          this.$bvModal.show(modal);
        } else {
          return this.toastMessage(
            "You don't have permission to perform this action",
            "Warning",
            "warning"
          );
        }
      }
    },
    getChecks(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLES_CHECKS_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_PAYMENTS, vehicle.vinNumber.vinNumber)
        .then(() => { })
        .catch(() => {
          this.toastMessage(
            "Payments could not be obtain",
            "Warning",
            "warning"
          );
        });
    },
    getConditionalReports(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLE_CONDITIONAL_REPORTS_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_VEHICLE_CONDITIONAL_REPORTS)
        .then(() => { })
        .catch(() => {
          this.toastMessage(
            "Conditional reports could not obtained",
            "Warning",
            "warning"
          );
        });
    },
    getNotes(resetFilters, vehicle) {
      this.$store.commit(SET_VEHICLE_NOTES_FILTERS, {
        reset: resetFilters,
        fields: { vehicle: vehicle.id },
      });
      this.$store
        .dispatch(GET_VEHICLE_NOTES)
        .then(() => { })
        .catch(() => {
          this.toastMessage("Notes could not obtained", "Warning", "warning");
        });
    },
    goToVisualInspection(vehicle) {
      const userHasPermission = this.userHasPermission("vi", "read");
      if (userHasPermission) {
        this.$router.push({
          name: "company.cars.general",
          params: { vin: vehicle.stockNumber },
        });
      } else {
        return this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },

    goToVisualInspectionCR(vehicle) {
      const userHasPermission = this.userHasPermission("vi", "read");
      if (userHasPermission) {
        this.$router.push({
          name: "company.cars.exterior.cr",
          params: { vin: vehicle.stockNumber },
        });
      } else {
        return this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }
    },

    getVarianPaymentColor(paymentStatus, totalChecks) {
      let variant = colors.GREY;
      if (paymentStatus == 2) {
        //Green
        variant = colors.GREEN;
      }
      if (paymentStatus == 1) {
        //Orange
        variant = colors.ORANGE;
      }

      /*  if (paymentStatus == 0) {
        //Red
        variant = "#dc3545";
      } */
      if (paymentStatus == 0 && totalChecks == 0) {
        variant = colors.GREY;
      }
      return variant;
    },
    getRecallColor(recallStatus) {
      let variant = colors.GREY;
      if (recallStatus == 1) {
        variant = colors.GREEN;
      }
      if (recallStatus == 2) {
        variant = colors.ORANGE;
      }
      if (recallStatus == 3) {
        variant = colors.GREEN;
      }
      if (recallStatus == 0 && recallStatus == 0) {
        variant = colors.GREY;
      }
      return variant;
    },
    getVarianCarfax(carfaxStatus) {
      let variant = "@/assets/svg/CF_grey.svg";

      switch (carfaxStatus) {
        case 1:
          //Green
          variant = "@/assets/svg/CF_success.svg";
          break;
        case 2:
          //Red
          variant = "@/assets/svg/CF_danger.svg";
          break;
        default:
          //Grey
          variant = "@/assets/svg/CF_grey.svg";
          break;
      }
      return variant;
    },
    getWorkOrderVariant(workorderStatus) {
      let variant = colors.GREY;
      switch (workorderStatus) {
        case 1:
          //Red
          variant = "#dc3545";

          break;
        case 2:
          variant = colors.YELLOW
          break;
        case 3:
          variant = colors.ORANGE;
          break;
        case 4:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getVisualInspectionVariant(visualInspectionStatus) {
      let variant = colors.GREY;
      switch (visualInspectionStatus) {
        case 2:
          variant = colors.GREEN;

          break;
        case 1:
          variant = colors.ORANGE;
          break;

        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getCarwashVariant(carwashStatus) {
      let variant = colors.GREY;
      switch (carwashStatus) {
        case 2:
          variant = colors.GREEN;
          break;
        case 1:
          variant = colors.ORANGE;
          break;

        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getTransferVariant(transferStatus) {
      let variant = colors.GREY;
      switch (transferStatus) {
        case 2:
          variant = colors.GREEN;
          break;
        case 1:
          variant = colors.ORANGE;
          break;
        case 3:
          //red
          variant = "#dc3545";
          break;
        case 4:
          //blue
          variant = "#02afaa";
          break;
        case 5:
          //strong green
          variant = "#19C631";
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getMarketplaceIcon(marketPlace) {
      switch (marketPlace) {
        case "QAS":
          return "https://apidev.transcanauto.com/storage/system/QAS_marketplace.svg";
        case "CANAM":
          return "https://apidev.transcanauto.com/storage/system/CANAM_marketplace.svg";
      }
    },
    getExportLoadVariant(exportLoadStatus) {
      let variant = colors.GREY;
      switch (exportLoadStatus) {
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          //blue
          variant = "#02afaa";
          break;
        case 3:
          variant = colors.GREEN;

          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getExportVariant(exportStatus) {
      let variant = colors.GREY;
      switch (exportStatus) {
        case 2:
          variant = colors.GREEN;
          break;
        case 1:
          variant = colors.ORANGE;
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getStockNumberVariant(stockNumberStatus) {
      let variant = colors.GREY;
      switch (stockNumberStatus) {
        case 3:
          variant = colors.GREEN;
          break;
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          //Red
          variant = "#dc3545";
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getCRVariant(crStatus) {
      let variant = colors.GREY;
      switch (crStatus) {
        case 1:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getLienVariant(lienStatus) {
      let variant = colors.GREY;
      switch (lienStatus) {
        case 1:
          variant = colors.GREEN;
          break;
        case 2:
          variant = colors.ORANGE;
          break;
        case 3:
          variant = colors.YELLOW;
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    getConsignmentVariant(consignmentStatus) {
      let variant = colors.GREY;
      switch (consignmentStatus) {
        case 1:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getExceptionVariant(exceptionStatus) {
      let variant = colors.GREY;
      switch (exceptionStatus) {
        case 1:
          //Red
          variant = "#dc3545";
          break;
        case 2:
          variant = colors.ORANGE;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getCreditRequestVariant(creditRequestStatus) {
      let variant = colors.GREY;
      switch (creditRequestStatus) {
        case 1:
          variant = colors.BLUE;
          break;
        case 2:
          variant = colors.ORANGE;
          break;
        case 3:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getPriceVariant(priceStatus) {
      let variant = colors.GREY;
      switch (priceStatus) {
        case 2:
          variant = colors.GREEN;
          break;
        case 1:
          variant = colors.ORANGE;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getRegistrationVariant(registrationStatus) {
      let variant = colors.GREY;
      switch (registrationStatus) {
        case 2:
          //Yellow
          variant = colors.YELLOW;
          break;
        case 3:
          variant = colors.ORANGE;
          break;
        case 4:
          //Red
          variant = "#dc3545";
          break;
        case 5:
          variant = colors.GREEN;
          break;
      }
      return variant;
    },
    getRegistrationVariantWithVehicleFile(registrationStatus, vehicle) {
      let variant = colors.GREY;
      if (vehicle.ownerSheet == null || registrationStatus == 2) {
        variant = colors.YELLOW;
        return variant;
      }
      if (registrationStatus == 3) {
        variant = colors.ORANGE;
        return variant;
      }
      if (registrationStatus == 4) {
        variant = "#dc3545";
        return variant;
      }

      if (registrationStatus == 5) {
        variant = colors.GREEN;
        return variant;
      }

      return variant;
    },
    getSoldVariant(soldStatus) {
      let variant = colors.GREY;
      switch (soldStatus) {
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getClusterVariant(clusterStatus) {
      let variant = colors.GREY;
      switch (clusterStatus) {
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }

      return variant;
    },
    gerReserveVariant(reserveStatus) {
      let variant = colors.GREY;
      switch (reserveStatus) {
        case 1:
          variant = colors.GREEN;
          break;
        default:
          //Grey
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getOffersVariant(offersStatus) {
      let variant = colors.GREY;
      switch (offersStatus) {
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          //blue
          variant = "#02afaa";
          break;

        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },
    getAddonVariant(addonStatus) {
      let variant = colors.GREY;

      switch (addonStatus) {
        case 1:
          variant = colors.ORANGE;
          break;
        case 2:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;

      }
      return variant;
    },
    getVisualInspectionCRVariant(visualInspectionCRStatus) {
      let variant = colors.GREY;
      switch (visualInspectionCRStatus) {
        case 1:
          variant = colors.YELLOW;
          break;
        case 2:
          variant = colors.GREEN;
          break;
        default:
          variant = colors.GREY;
          break;
      }
      return variant;
    },

    hasFilter(key) {
      if (
        key == "marketPlace" ||
        key == "user" ||
        key == "payments" ||
        key == "carfax" ||
        key == "recall" ||
        key == "visualInspection" ||
        key == "workOrders" ||
        key == "carwash" ||
        key == "transfer" ||
        key == "files" ||
        key == "stockNumber" ||
        key == "lien" ||
        key == "creditRequest" ||
        key == "registration" ||
        key == "conditionalReports" ||
        key == "prices" ||
        key == "sold" ||
        key == "cluster" ||
        key == "offers" ||
        key == "reserve" ||
        key == "addons" ||
        key == "soldInfo"
      ) {
        return true;
      }
      return false;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.alert === 1) return "table-danger";
      if (item.stockNumber === this.vehicleAnchor) return "table-info";
    },

    userHasPermission(module, action) {
      const permissions = this.userPermissions;
      const permissionModule = permissions.find((permission) => {
        return permission.module === module;
      });
      if (!permissionModule) return false;

      return permissionModule[action] == 1 ? true : false;
    },
    userHasMenuPermission(name) {
      const permissions = this.userMenuPermissions;
      if (!permissions) return false;
      const menuPermission = permissions.find((permission) => {
        return permission.name == name;
      }
      );
      return menuPermission ? true : false;
    },
    scrollToAnchor(stockNumber) {
      const element = document.getElementById(stockNumber);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    updateVehicleFlag(vehicleId, flag, value) {
      let vehiclesToFindFlag = [...this.vehicles.data.paginate.data];
      const index = vehiclesToFindFlag.findIndex(
        (vehicle) => vehicle.id == vehicleId
      );
      this.$store.commit("UPDATE_VEHICLE_FLAG", {
        index: index,
        flag: flag,
        value: value,
      });
    },
    updateVehicleReleaseDate(vehicleId, releaseDate) {
      let vehiclesToFindFlag = [...this.vehicles.data.paginate.data];
      const index = vehiclesToFindFlag.findIndex(
        (vehicle) => vehicle.id == vehicleId
      );
      this.$store.commit("UPDATE_VEHICLE_RELEASE_DATE", {
        index: index,
        releaseDate: releaseDate,
      });
    },
    openOfferSidebar(vehicle) {
      const userHasPermission = this.userHasPermission(
        'offers',
        'read'
      );
      if (userHasPermission) {
        this.$store.commit("SHOW_OFFER_SIDEBAR", false);
        this.$store.commit("SHOW_OFFERS_SIDEBAR", false);
        let vehicleFormatted = this.formatVehicleInformation(vehicle);
        this.getVehicleOffers(vehicleFormatted);
        setTimeout(() => {
          this.$store.commit("SHOW_OFFER_SIDEBAR", true);
        }, 1000);
      } else {
        this.toastMessage(
          "You don't have permission to perform this action",
          "Warning",
          "warning"
        );
      }

    },
    getVehicleOffers(vehicle) {
      this.$store
        .dispatch("GET_OFFERS_BY_VEHICLE", vehicle.id)
        .then(() => {
          // SET VEHICLE DATA TO SHOW IN SIDEBAR
/*           this.vehicle.isActiveOffer = this.isActiveOffer;
 */          this.$store.commit("SET_OFFER_SIDEBAR", vehicle);

          this.markNotificationAsRead(vehicle);
        })

        .catch((error) => {
          console.log(error);
          this.toastMessage(
            "The vehicle offers could not be obtained",
            "Warning",
            "warning"
          );
        });
    },
    markNotificationAsRead(vehicle) {
      const payload = {
        id: vehicle.id,
      };
      this.$store.commit("MARK_NOTIFICATION_AS_READ", {});

      this.$store.dispatch("MARK_NOTIFICATION_AS_READ", payload).then(() => { });
    },
    formatVehicleInformation(vehicle) {
      let vehicleFormatted = {
        ...vehicle.vehicleCostDetail, ...vehicle.vinNumber, ...vehicle.vehicleInformation
        , vehicleId: vehicle.id

      }
      return vehicleFormatted

    },
    validateOpenModal(vehicle) {
      if (this.corporationSelected.id == vehicle.corporationsId && vehicle.status == "InStock" && vehicle.vehicleFlags?.consignment == 1) {
        return false
      }
      return true
    }

  },
};

export { superAdminMixin };

