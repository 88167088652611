<template>
  <div class="text-center">
    <div class="fullscreen-camera">
      <div class="camera-stack">
        <div class="camera-header">
          <AutoParts
            ref="autoParts"
            @select-auto-part="setAutoPartSelected"
            @stop-camera="onStopCamera"
            :vin="$route.params.vin"
          />
        </div>
        <div v-if="photo == null" class="video-wrapper">
          <WebCam
            ref="webcam"
            :resolution="{
              width: 1920,
              height: 1080,
            }"
            autoplay
            @cameras="setCamerasAvailable"
            :deviceId="selectedDevice"
            screenshotFormat="blob"
          ></WebCam>
        </div>
        <div
          v-if="photo"
          class="camera-snap"
          :style="{ backgroundImage: `url('${photo}')` }"
        ></div>
        <div class="camera-stack-controls">
          <div class="camera-stack-controls-wrapper">
            <template v-if="!photo">
              <div v-if="cameras.length > 1" class="control-slot"></div>

              <div class="control-slot">
                <a role="button" @click="capture()">
                  <i class="mdi mdi-circle"> </i>
                </a>
              </div>
              <div class="control-slot2">
                <b-dropdown size="md" variant="link" no-caret class="p-0">
                  <template #button-content>
                    <font-awesome-icon
                      class="icon-color cursor-pointer text-black"
                      :icon="['far', 'camera-rotate']"
                      size="lg"
                      style="font-size: 40px; color: black"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="(camera, key) in cameras"
                    :key="key"
                    @click="changeCamera(camera.deviceId)"
                  >
                    <small class="text-black">
                      {{ camera.label }}
                    </small></b-dropdown-item
                  >
                </b-dropdown>
              </div></template
            >

            <template v-else>
              <div class="control-slot"></div>
              <div class="control-slot">
                <a role="button" @click="savePicture()">
                  <i class="mdi mdi-check"></i>
                </a>
              </div>
              <div class="control-slot">
                <a role="button" class="secondary-button" @click="photo = null">
                  <i class="mdi mdi-camera-retake-outline"></i>
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoParts from "./AutoParts.vue";
import CompanyCarsService from "@/services/companyCarsService";
import { generalMixin } from "@/modules/mixin.js";
import { companyCarsMixin } from "@/modules/vehicle/components/visual-inspection/mixin";
import { WebCam } from "vue-web-cam";

export default {
  name: "CaptureImageBetterResolution",
  components: { WebCam, AutoParts },
  mixins: [generalMixin, companyCarsMixin],
  data() {
    return {
      picture: null,
      car: null,
      lastPhotoTypeUpload: "",
      photo: null,
      cameras: [],
      selectedDevice: null,
    };
  },

  methods: {
    async uploadPicture() {
      try {
        const payload = await this.formatPayload();
        await CompanyCarsService.postPic(payload);
        this.makeToast(
          "Success",
          this.lastPhotoTypeUpload + " photo added successfully",
          "success"
        );
        this.photo = null;
        this.getData();
      } catch (error) {
        console.log(error);
        this.makeToast(
          "Error",
          "Internal server error, try again later",
          "danger"
        );
      } finally {
        this.picture = null;
        this.photo = null;
      }
    },
    onApprove(picture) {
      this.picture = picture;
      this.savePicture();
      this.$refs.webcam.start();
    },
    setAutoPartSelected(autoPart) {
      this.autoPartSelected = autoPart;
    },
    savePicture() {
      this.uploadPicture();
      this.$refs.autoParts.skipAutoPart();
    },
    async formatPayload() {
      const blobPicture = await fetch(this.photo).then((r) => r.blob());
      let pictureFile = new File([blobPicture], "picture.png", {
        type: "image/png",
      });

      this.lastPhotoTypeUpload = this.autoPartSelected.name;

      const payload = {
        photo: pictureFile,
        partsId: this.autoPartSelected.id,
        vehiclesId: this.car.id,
        vi: this.$route.name.includes("cr") ? 1 : 0,
      };
      return payload;
    },

    onStopCamera() {
      console.log("stop camera");
      this.$refs.webcam.stop();
      if (this.$route.name.includes("cr")) {
        this.$router.push({
          name: "company.cars.exterior.cr",
          params: { vin: this.$route.params.vin },
        });
      } else {
        this.$router.push({
          name: "company.cars.exterior.cr",
          params: { vin: this.$route.params.vin },
        });
      }
    },

    getData() {
      this.getVin(this.$route.params.vin, (data) => {
        this.$store.commit("POST_VEHICLE_BY_STOCK_SUCCESS", data);
        this.car = data;
      });
    },
    capture() {
      this.photo = this.$refs.webcam.capture();
    },
    setCamerasAvailable(cameras) {
      this.cameras = cameras;
      const selectedCameraDeviceId = localStorage.getItem(
        "selectedCameraDeviceId"
      );
      this.selectedDevice = selectedCameraDeviceId ?? cameras[0].deviceId;
      this.$refs.webcam.start();
    },
    changeCamera(deviceId) {
      console.log(deviceId);

      this.selectedDevice = deviceId;
      /* save on localStore */
      localStorage.setItem("selectedCameraDeviceId", deviceId);
      /*    this.selectedDevice = this.cameras?.find(
        (camera) => camera.deviceId !== this.selectedDevice
      ).deviceId; */
    },
    base64ToFile(base64String, fileName, mimeType) {
      // Decode the Base64 string (remove any prefix like "data:image/png;base64,")
      const base64Data = base64String.split(",").pop(); // Optional: Removes the prefix

      // Convert Base64 string to byte array
      const binaryString = atob(base64Data); // Decode Base64
      const len = binaryString.length;
      const byteArray = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob object from the byte array
      const file = new File([byteArray], fileName, { type: mimeType });

      return file;
    },
  },
  mounted() {
    this.getData();
    console.log(this.$refs);
    // this.createCameraElement();
  },
};
</script>

<style scope lang="scss">
.fullscreen-camera {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 24;
  .camera-stack {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    .camera-stack-header {
      a {
        &.close-button {
          position: fixed;
          top: 5px;
          right: 15px;
          color: #fff;
          font-family: Roboto, Tahoma;
          font-size: 2.5rem;
          line-height: 40px;
          font-weight: 300 !important;
          z-index: 100;
        }
      }
    }
    .video-wrapper {
      height: 100%;
      width: 100%;
      align-self: stretch;
      align-items: center;
      position: relative;
      display: flex;
      flex-direction: column;
      video {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .overlay-mask {
        max-width: 50vh;
        max-height: 50vh;
        width: 100%;
        height: 100%;
        margin-top: 10vh;
        &.visible-overlay {
          box-shadow: 0px 0px 2000px 2000px rgba(176, 176, 175, 0.6) !important;
        }
        img {
          width: 100%;
        }
      }
    }
    .camera-snap {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
    .camera-stack-controls {
      width: 100%;
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 0;
      padding: 5vh 0px;
      background-color: rgba(176, 176, 175, 0.6) !important;
      &.background::after {
        background-color: rgba(176, 176, 175, 0.6) !important;
      }
      .camera-stack-controls-wrapper {
        max-width: 500px;
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .control-slot {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.8;
          a {
            color: #000000;
            border-radius: 100%;
            font-size: 35pt;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 1px 2px #fff;
            .mdi-check {
              color: #39b54a !important;
            }
            .mdi-circle {
              color: red !important;
            }

            .mdi-camera-retake-outline {
              color: #fff !important;
            }

            i {
              &:before {
                display: block !important;
                line-height: normal !important;
              }
            }
            &.secondary-button {
              font-size: 15pt;
              padding: 10px;
            }
          }
        }
        .control-slot2 {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.8;
          a {
            color: #000000;
            border-radius: 100%;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 1px 2px #fff;
            .mdi-check {
              color: #39b54a !important;
            }
            .mdi-circle {
              color: red !important;
            }

            .mdi-camera-retake-outline {
              color: #fff !important;
            }

            i {
              &:before {
                display: block !important;
                line-height: normal !important;
              }
            }
            &.secondary-button {
              font-size: 15pt;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
