export const GET_VEHICLES_INFO = "GET_VEHICLES_INFO";
export const GET_VEHICLES_INFO_SUCCCESS = "GET_VEHICLES_INFO_SUCCCESS";
export const GET_VEHICLES_INFO_ERROR = "GET_VEHICLES_INFO_ERROR";

export const GET_VEHICLES_INFO_FOR_RI_ROLE = "GET_VEHICLES_INFO_FOR_RI_ROLE";
export const GET_VEHICLES_INFO_FOR_RI_ROLE_SUCCESS = "GET_VEHICLES_INFO_FOR_RI_ROLE_SUCCESS";
export const GET_VEHICLES_INFO_FOR_RI_ROLE_ERROR = "GET_VEHICLES_INFO_FOR_RI_ROLE_ERROR";

export const SET_VEHICLE_INFO = "SET_VEHICLE_INFO";
export const SET_VEHICLES_INFO_FILTERS = "SET_VEHICLES_INFO_FILTERS";

export const CLEAR_VEHICLE_TRIMS = "CLEAR_VEHICLE_TRIMS";

export const CLEAR_VEHICLE_MODELS = "CLEAR_VEHICLE_MODELS";

export const GET_VEHICLES_EXCEL = "GET_VEHICLES_EXCEL";
export const GET_VEHICLES_EXCEL_SUCCESS = "GET_VEHICLES_EXCEL_SUCCESS";
export const GET_VEHICLES_EXCEL_ERROR = "GET_VEHICLES_EXCEL_ERROR";

export const SET_VEHICLE_ANCHOR = "SET_VEHICLE_ANCHOR";

export const UPDATE_VEHICLE_FLAG = "UPDATE_VEHICLE_FLAG";

export const UPDATE_VEHICLE_RELEASE_DATE = "UPDATE_VEHICLE_RELEASE_DATE";

export const POST_CREATE_QUICK_RI_TRANSFER = "POST_CREATE_QUICK_RI_TRANSFER"
export const POST_CREATE_QUICK_RI_TRANSFER_SUCCESS = "POST_CREATE_QUICK_RI_TRANSFER_SUCCESS"
export const POST_CREATE_QUICK_RI_TRANSFER_ERROR = "POST_CREATE_QUICK_RI_TRANSFER_ERROR"
