<template>
  <div
    v-touch:touchhold="touchHoldHandler"
    @contextmenu.prevent="$refs.menuTransfer.open($event, 'Payload')"
  >
    <slot></slot>
    <ContextMenu ref="menuTransfer">
      <template slot-scope>
        <ContextMenuItem @click.native="getCRFile(vehicle.id)">
          Download CRVI file
          <!-- loader  -->
          <b-spinner
            v-if="isVicrFileLoading"
            small
            label="Spinning"
          ></b-spinner>
        </ContextMenuItem>
      </template>
    </ContextMenu>
  </div>
</template>

<script>
import { superAdminMixin } from "../../mixins";
import ContextMenu from "../contextMenu/ContextMenu.vue";
import ContextMenuItem from "../contextMenu/ContextMenuItem.vue";
import { GET_CR_FILE } from "./actions";
export default {
  name: "VICRContextMenu",
  props: ["vehicle", "refreshData"],
  mixins: [superAdminMixin],
  components: {
    ContextMenu,
    ContextMenuItem,
  },
  data() {
    return {
      isVicrFileLoading: false,
    };
  },

  methods: {
    touchHoldHandler($event) {
      this.$refs.menuTransfer.open($event, "Payload");
    },
    getCRFile(vehicleId) {
      this.isVicrFileLoading = true;
      this.$store
        .dispatch(GET_CR_FILE, { id: vehicleId })
        .then((response) => {
          if (!response.error) {
            const url = response.url;
            const link = document.createElement("a");
            /* open in blank */
            link.target = "_blank";
            link.setAttribute("href", url);
            link.setAttribute("download", "cr.pdf");
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.isVicrFileLoading = false;
        });
    },
  },
};
</script>

<style></style>
