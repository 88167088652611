import * as constants from "./actions";
import axios from "@/plugins/axios.js";
function getDefaultState() {
  return {
    crFile: {
      status: null,
      data: {},
      error: null,
    },

  };
}

const actions = {
  [constants.GET_CR_FILE]: async ({ commit }, data) => {
    try {
      commit(constants.GET_CR_FILE);
      const response = await axios.get(`vehicle/downloadCr`, { params: data });
      commit(constants.GET_CR_FILE_SUCCESS, response);
      return response;
    } catch (error) {
      commit(constants.GET_CR_FILE_ERROR, error);
      throw error.response;
    }
  }
};

const mutations = {
  [constants.GET_CR_FILE]: (state) => {
    state.crFile.status = "fetching";
    state.crFile.error = null;
  },
  [constants.GET_CR_FILE_SUCCESS]: (state, data) => {
    state.crFile.status = "success";
    state.crFile.data = data;
    state.crFile.error = null;
  },
  [constants.GET_CR_FILE_ERROR]: (state, error) => {
    state.crFile.status = "error";
    state.crFile.error = error;
  },
}


export default {
  state: getDefaultState(),
  actions,
  mutations,
};
